import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { Fragment } from 'react';
import { CheckIcon, MinusIcon } from '@heroicons/react/24/solid';
// import { CheckIcon , MinusIcon } from '@heroicons/react/outline';
import { withFirebase } from '../../firebase/Firebase';

import SpareMicLogo from '../../assets/images/SpareMicLogo.png';


import Terms from '../../assets/pdfs/TermsOfUse.pdf';
import Privacy from '../../assets/pdfs/PrivacyPolicy.pdf';
import PressRelease from '../../assets/pdfs/PressRelease.pdf';

import { logEvent } from 'firebase/analytics';

import './pricing.styles.scss';

const tiers = [
    {
        name: 'Free',
        href: '#',
        priceMonthly: 0,
        description: 'Unlimited watermarked video previews and exports' },
    {
        name: 'Weekly',
        href: '#',
        priceMonthly: 3.99,
        description: '3 Day Free Trial plus Unlock All App Features',
    },
    {
        name: 'Yearly',
        href: '#',
        priceMonthly: 29.99,
        description: 'Save 86% plus Unlock All App Features',
    }
]
const sections = [
    {
        name: 'Features',
        features: [
        { name: 'High quality video recording', tiers: { Free: true, Weekly: true, Yearly: true } },
        { name: 'Built-in audio recording', tiers: { Free: true, Weekly: true, Yearly: true } },
        { name: 'Nearby device audio recording', tiers: { Free: true, Weekly: true, Yearly: true } },
        { name: 'Video Metadata', tiers: { Free: true, Weekly: true, Yearly: true } },
        { name: 'Audio Metadata', tiers: { Free: true, Weekly: true, Yearly: true } },
        { name: 'Sort Videos (by Date, Duration, Filesize)', tiers: { Free: true, Weekly: true, Yearly: true } },
        { name: 'Video Previews', tiers: { Free: 'Watermarked', Weekly: true, Yearly: true } },
        { name: 'Video Exports', tiers: { Free: 'Watermarked', Weekly: true, Yearly: true } },
        { name: 'Live Listen', tiers: { Weekly: true, Yearly: true } },
        { name: 'Mirror Camera', tiers: { Weekly: true, Yearly: true } },
        { name: 'Switch Microphones (front, back, bottom)', tiers: { Weekly: true, Yearly: true } },
        { name: 'Multiple Video Presets (Resolution & FPS)', tiers: { Weekly: true, Yearly: true } },
        { name: 'Teleprompter', tiers: { Weekly: true, Yearly: true } },
        { name: 'Subtle Audio Visualization', tiers: { Weekly: true, Yearly: true } },
        { name: 'Custom Branding', tiers: { Weekly: true, Yearly: true} },
        { name: 'Foldback Messages', tiers: { Weekly: true, Yearly: true } },
        { name: 'Timecode Syncing', tiers: { Weekly: true, Yearly: true } },
        ],
    },
    {
        name: 'Reporting',
        features: [
        { name: 'Give Feedback', tiers: { Free: true, Weekly: true, Yearly: true } },
        { name: 'Request a Feature', tiers: { Free: true, Weekly: true, Yearly: true } },
        { name: 'Report a Bug', tiers: { Free: true, Weekly: true, Yearly: true } },
        { name: 'Recommend to a Friend', tiers: { Free: true, Weekly: true, Yearly: true } },
        ],
    },
    {
        name: 'Support',
        features: [
        { name: 'In-app Guide', tiers: { Free: true, Weekly: true, Yearly: true } },
        { name: 'Instructional Videos', tiers: { Free: true, Weekly: true, Yearly: true } },
        { name: 'Website & Contact Us Page', tiers: { Free: true, Weekly: true, Yearly: true } },
        { name: 'Email Support (support@sparemic.app)', tiers: { Free: true, Weekly: true, Yearly: true } },
        { name: 'Phone Support', tiers: { Weekly: true, Yearly: true } },
        ],
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class PricingPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      firebase: props.firebase,
      appStoreLink: 'https://apps.apple.com/us/app/spare-mic-wireless-mic-app/id6443896041'
    }
  }

  componentDidMount() {
    document.title = 'Pricing - Spare Mic'
  }

  componentWillUnmount() {
    // Reset the title when the component is unmounted
    document.title = 'Spare Mic'
  }

  handleDownloadClick = async event => {
    // prevent the anchor from its default behavior
    event.preventDefault();

    await logEvent(this.state.firebase.analytics, 'download_link_clicked', {
      route: '/pricing'
    });

    // navigate to the link provided after the log event using a delay of 3 seconds
    setTimeout(() => {
      window.location.href = this.state.appStoreLink; // event.currentTarget.href;
  }, 3000)
  }

  render() {
      return (
          <div className="spare-mic">
              <div className='header'>
                  <NavLink to='/sparemic' className='logo-container'>
                      <img src={SpareMicLogo} className='logo' alt='logo' loading='eager' height='50'/>
                      <span className='logo-text'> Spare Mic </span>
                  </NavLink>
                  <div className='options'>

                      <NavLink className='option' to='/sparemic'> Home </NavLink>

                      <button className='option' onClick={this.handleDownloadClick}> Download </button>

                      {/* <div className='option'>
                          <a href="https://apps.apple.com/us/app/spare-mic-wireless-mic-app/id6443896041"> Download </a>
                      </div> */}
                  </div>
              </div>

              <div className='container'>
                <h4> Pricing </h4>
                <h2> Something for Everyone </h2>
                <h3> Try Spare Mic for free and upgrade to unlock features that suit your needs</h3>

                <div className="bg-white rounded-3xl mt-10">
                  <div className="max-w-7xl mx-auto bg-white py-8 sm:py-8 sm:px-6 lg:px-8 rounded-3xl">
                    {/* xs to lg */}
                    <div className="max-w-2xl mx-auto space-y-16 lg:hidden">
                      {tiers.map((tier, tierIdx) => (
                        <section key={tier.name}>
                          <div className="px-4 mb-8">
                            <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.name}</h2>
                            <p className="mt-4">
                              <span className="text-4xl font-extrabold text-gray-900">${tier.priceMonthly}</span>{' '}
                              {/* <span className="text-base font-medium text-gray-500">/mo</span> */}
                            </p>
                            <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                            {/* <a
                              href={tier.href}
                              className="mt-6 block border border-gray-800 rounded-md bg-gray-800 w-full py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                            >
                              Buy {tier.name}
                            </a> */}
                          </div>

                          {sections.map((section) => (
                            <table key={section.name} className="w-full">
                              <caption className="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">
                                {section.name}
                              </caption>
                              <thead>
                                <tr>
                                  <th className="sr-only" scope="col">
                                    Feature
                                  </th>
                                  <th className="sr-only" scope="col">
                                    Included
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200">
                                {section.features.map((feature) => (
                                  <tr key={feature.name} className="border-t border-gray-200">
                                    <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                                      {feature.name}
                                    </th>
                                    <td className="py-5 pr-4">
                                      {typeof feature.tiers[tier.name] === 'string' ? (
                                        <span className="block text-sm text-gray-700 text-right">{feature.tiers[tier.name]}</span>
                                      ) : (
                                        <>
                                          {feature.tiers[tier.name] === true ? (
                                            <CheckIcon className="ml-auto h-5 w-5 text-green-500" aria-hidden="true" />
                                          ) : (
                                            <MinusIcon className="ml-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                                          )}

                                          <span className="sr-only">{feature.tiers[tier.name] === true ? 'Yes' : 'No'}</span>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ))}

                          <div
                            className={classNames(
                              tierIdx < tiers.length - 1 ? 'py-5 border-b' : 'pt-5',
                              'border-t border-gray-200 px-4'
                            )}
                          >
                            {/* <a
                              href={tier.href}
                              className="block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                            >
                              Buy {tier.name}
                            </a> */}
                          </div>
                        </section>
                      ))}
                    </div>

                    {/* lg+ */}
                    <div className="hidden lg:block ">
                      <table className="w-full h-px table-auto">
                        <caption className="sr-only">Pricing plan comparison</caption>
                        <thead>
                          <tr>
                            <th className="pb-4 px-6 text-sm font-medium text-gray-900 text-left" scope="col">
                              <span className="sr-only">Feature by</span>
                              <span>Packages</span>
                            </th>
                            {tiers.map((tier) => (
                              <th
                                key={tier.name}
                                className="w-1/5 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
                                scope="col"
                              >
                                {tier.name}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="border-t border-gray-200 divide-y divide-gray-200">
                          <tr>
                            <th className="py-8 px-6 text-sm font-medium text-gray-900 text-left align-top" scope="row">
                              Pricing
                            </th>
                            {tiers.map((tier) => (
                              <td key={tier.name} className="h-full py-8 px-4 align-top" >
                                <div className="relative h-full table">
                                  <p>
                                    <span className="text-4xl font-extrabold text-gray-900">${tier.priceMonthly}</span>{' '}
                                    {/* <span className="text-base font-medium text-gray-500">/mo</span> */}
                                  </p>
                                  <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                                  {/* <a
                                    href={tier.href}
                                    className="absolute bottom-0 flex-grow block w-full bg-gray-800 border border-gray-800 rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                                  >
                                    Buy {tier.name}
                                  </a> */}
                                </div>
                              </td>
                            ))}
                          </tr>
                          {sections.map((section) => (
                            <Fragment key={section.name}>
                              <tr>
                                <th
                                  className="bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left"
                                  colSpan={6}
                                  scope="colgroup"
                                >
                                  {section.name}
                                </th>
                              </tr>
                              {section.features.map((feature) => (
                                <tr key={feature.name}>
                                  <th className="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
                                    {feature.name}
                                  </th>
                                  {tiers.map((tier) => (
                                    <td key={tier.name} className="py-5 px-6">
                                      {typeof feature.tiers[tier.name] === 'string' ? (
                                        <span className="block text-sm text-gray-700">{feature.tiers[tier.name]}</span>
                                      ) : (
                                        <div className=' items-center'>
                                          {feature.tiers[tier.name] === true ? (
                                            <CheckIcon className="h-5 w-5 text-blue-800" aria-hidden="true" />
                                          ) : (
                                            <MinusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                          )}

                                          <span className="sr-only">
                                            {feature.tiers[tier.name] === true ? 'Included' : 'Not included'} in {tier.name}
                                          </span>
                                        </div>
                                      )}
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </Fragment>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr className="border-t border-gray-200">
                            <th className="sr-only" scope="row">
                              Choose your plan
                            </th>
                            {tiers.map((tier) => (
                              <td key={tier.name} className="pt-5 px-6">
                                {/* <a
                                  href={tier.href}
                                  className="block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                                >
                                  Buy {tier.name}
                                </a> */}
                              </td>
                            ))}
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>


                {/* Download Button */}
                <div className='m-20'>
                  <a href="https://apps.apple.com/us/app/spare-mic-wireless-mic-app/id6443896041" className='text-xl text-indigo-600 font-bold bg-white p-8 rounded-full  '> Download for Free </a>
                </div>
              </div>

              <div className="container container-gray">
                  <div className='footer'>
                      <div className='links'>
                          {/* <NavLink className='option' to='/about'> About </NavLink>
                          <NavLink className='option' to='/contact'> Contact </NavLink> */}
                          <a className='option' href={Terms}> Terms of Use  </a>
                          <a className='option' href={Privacy}> Privacy Policy </a>
                          <a className='option' href={PressRelease}>Press</a>
                      </div>

                      <div className='copyright'>Spare Mic <br/>Copyright © 2022 Synergie Tech, Inc. <br/>All Rights Reserved </div>
                  </div>
              </div>
          </div>

      )
  }
}

export default withRouter(withFirebase(PricingPage));
